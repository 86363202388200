var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sidebar",staticClass:"sidebar",class:{
    'sidebar--collapsed': _vm.collapsed,
    'sidebar--expanded': !_vm.collapsed
  }},[_vm._l((_vm.menuItems),function(item,i){return [_c('div',{key:i,class:{ 'sub-item': item.subMenus },on:{"mouseover":function($event){return _vm.handleMouseHover($event, i)},"click":function($event){_vm.showingHover = false;
        _vm.handleLinkClick(i, item);}}},[(!item.subMenus)?_c('router-link',{staticClass:"sidebar__item",class:{
          'sidebar__item--active':
            _vm.activeItemIndex === i || (_vm.hoverIndex === i && _vm.showingHover)
        },attrs:{"to":item.to}},[_c('fa-icon',{staticClass:"my-icon",attrs:{"icon":item.icon}}),_c('div',{staticClass:"sidebar-item-text"},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('router-link',{staticClass:"sidebar__item",class:{
          'sidebar__item--active':
            _vm.activeItemIndex === i || (_vm.hoverIndex === i && _vm.showingHover)
        },attrs:{"to":item.to},nativeOn:{"click":function($event){return _vm.toggleSubmenu(item, i)}}},[_c('fa-icon',{staticClass:"my-icon",attrs:{"icon":item.icon}}),_c('div',{staticClass:"sidebar-item-text space-between align-center"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:{
              'arrow-down': !item.expanded,
              'arrow-up': item.expanded
            }})])],1),_vm._l((item.subMenus),function(itm,ii){return [_c('transition',{key:ii,attrs:{"name":"height"}},[(item.expanded && !_vm.collapsed)?_c('router-link',{key:ii,staticClass:"sidebar__item sidebar__item--sub",attrs:{"to":itm.to},nativeOn:{"click":function($event){return _vm.handleSubItemClick($event)}}},[_c('fa-icon',{staticClass:"my-icon",attrs:{"icon":itm.icon}}),_c('div',{staticClass:"sidebar-item-text"},[_vm._v(" "+_vm._s(itm.name)+" ")])],1):_vm._e()],1)]})],2)]}),_c('div',{ref:"testHover",staticClass:"test-hover",style:({ visibility: _vm.showingHover ? 'visible' : 'hidden' }),on:{"mouseleave":_vm.hideMenu}},_vm._l((_vm.menuItems),function(item,i){return _c('div',{key:i,on:{"click":function($event){_vm.showingHover = false}}},[(_vm.hoverIndex === i)?_c('router-link',{staticClass:"sidebar__item",class:{ 'sidebar__item--active': _vm.hoverIndex === i },attrs:{"to":item.to}},[_c('div',{staticClass:"sidebar-item-text--hover space-between align-center"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(item.subMenus)?_c('div',{staticClass:"arrow-down"}):_vm._e()])]):_vm._e(),_vm._l((item.subMenus),function(itm,ii){return [(_vm.hoverIndex === i)?_c('router-link',{key:ii,staticClass:"sidebar__item sidebar__item--sub collapse-sub",attrs:{"to":itm.to},nativeOn:{"click":function($event){_vm.activeItemIndex = i}}},[_c('fa-icon',{staticClass:"my-icon",attrs:{"icon":itm.icon}}),_c('div',{staticClass:"sidebar-item-text--collapsed"},[_vm._v(" "+_vm._s(itm.name)+" ")])],1):_vm._e()]})],2)}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }