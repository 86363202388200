var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"my-button",class:{
    'my-button--outline': _vm.type === 'outline',
    'my-button--outline-blue': _vm.type === 'outline-blue',
    'my-button--orange': _vm.type === 'orange',
    'my-button--blue': _vm.type === 'blue',
    'my-button--sm': _vm.size === 'sm',
    'my-button--lg': _vm.size === 'lg',
    'my-button--flat': _vm.flat || _vm.flat === '',
    'my-button--narrow': _vm.narrow || _vm.narrow === '',
    'my-button--block': _vm.block || _vm.block === '',
    'my-button--rounded': _vm.rounded !== false
  },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }