import * as types from "../types";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;

const API_SHOP_LIST = types.API_BASE_URL + "shop/list";
const API_SHOP_VERIFY = types.API_BASE_URL + "shop/verify";
const API_SHOP_CREATE = types.API_BASE_URL + "auth/register-by-admin"
const API_ROLE_LIST = types.API_BASE_URL + "role/list";
const API_USER_LIST = types.API_BASE_URL + "user/list";
const API_USER_VERIFY = types.API_BASE_URL + "user/verify";
const API_USER_ROLE_UPDATE = types.API_BASE_URL + "user/role";
const API_ORDER_LIST = types.API_BASE_URL + "order/list";
const API_ORDER_DETAIL = types.API_BASE_URL + "order/detail";
const API_MARKETING_KIT_LIST = types.API_BASE_URL + "marketing/list/all";
const API_MARKETING_KIT_DETAIL = types.API_BASE_URL + "marketing/detail";
const API_MARKETING_KIT_CREATE = types.API_BASE_URL + "marketing/create";
const API_MARKETING_KIT_UPDATE = types.API_BASE_URL + "marketing/update";
const API_MARKETING_KIT_DELETE = types.API_BASE_URL + "marketing/delete";
const API_MARKETING_ITEM_CREATE = types.API_BASE_URL + "marketing/item/create";
const API_MARKETING_ITEM_UPDATE = types.API_BASE_URL + "marketing/item/update";
const API_MARKETING_ITEM_DELETE = types.API_BASE_URL + "marketing/item/delete";
const API_MARKETING_CATEGORY_LIST = types.API_BASE_URL + "marketing/category/list";
const API_MARKETING_CATEGORY_CREATE = types.API_BASE_URL + "marketing/category/create";
const API_MARKETING_CATEGORY_UPDATE = types.API_BASE_URL + "marketing/category/update";
const API_MARKETING_CATEGORY_DELETE = types.API_BASE_URL + "marketing/category/delete";
const API_MARKETING_TYPE_LIST = types.API_BASE_URL + "marketing/type/list";
const API_MARKETING_TYPE_CREATE = types.API_BASE_URL + "marketing/type/create";
const API_MARKETING_TYPE_UPDATE = types.API_BASE_URL + "marketing/type/update";
const API_MARKETING_TYPE_DELETE = types.API_BASE_URL + "marketing/type/delete";
const API_MARKETING_KIT_PUBLISH = types.API_BASE_URL + "marketing/published";
const API_SHOP_CATEGORY_LIST = types.API_BASE_URL + "shop/category/list";
const API_SHOP_CATEGORY_CREATE = types.API_BASE_URL + "shop/category/create";
const API_SHOP_CATEGORY_UPDATE = types.API_BASE_URL + "shop/category/update";
const API_SHOP_CATEGORY_DELETE = types.API_BASE_URL + "shop/category/delete";
const API_MARKETING_PRO_LIST = types.API_BASE_URL + "marketing-pro/list/all";
const API_MARKETING_PRO_DETAIL = types.API_BASE_URL + "marketing-pro/detail";
const API_MARKETING_PRO_CREATE = types.API_BASE_URL + "marketing-pro/create";
const API_MARKETING_PRO_UPDATE = types.API_BASE_URL + "marketing-pro/update";
const API_MARKETING_PRO_DELETE = types.API_BASE_URL + "marketing-pro/delete";
const API_MARKETING_PRO_BACKGROUND = types.API_BASE_URL + "marketing-pro/background";
const API_MARKETING_PRO_ITEM_CREATE = types.API_BASE_URL + "marketing-pro/item/create";
const API_MARKETING_PRO_ITEM_UPDATE = types.API_BASE_URL + "marketing-pro/item/update";
const API_MARKETING_PRO_ITEM_DELETE = types.API_BASE_URL + "marketing-pro/item/delete";
const API_MARKETING_PRO_PUBLISH = types.API_BASE_URL + "marketing-pro/published";
const API_MARKETING_PRO_TYPE_LIST = types.API_BASE_URL + "marketing-pro/type/list";
const API_MARKETING_PRO_TYPE_CREATE = types.API_BASE_URL + "marketing-pro/type/create";
const API_MARKETING_PRO_TYPE_UPDATE = types.API_BASE_URL + "marketing-pro/type/update";
const API_MARKETING_PRO_TYPE_DELETE = types.API_BASE_URL + "marketing-pro/type/delete";
const API_FONT_LIST = types.API_BASE_URL + "font/list";
const API_FONT_CREATE = types.API_BASE_URL + "font/create";
const API_FONT_UPDATE = types.API_BASE_URL + "font/update";
const API_FONT_DELETE = types.API_BASE_URL + "font/delete";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.GET_USER_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_USER_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_SHOP_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SHOP_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.VERIFY_SHOP]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SHOP_VERIFY, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_SHOP]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SHOP_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data);
          }
        })
        .catch(error => {
          console.log(error);
          console.log(payload);
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_ROLE_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ROLE_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.VERIFY_USER]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_USER_VERIFY, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_USER_ROLE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_USER_ROLE_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_ORDER_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ORDER_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_ORDER_DETAIL]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_ORDER_DETAIL, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_KIT_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MARKETING_KIT_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_KIT_DETAIL]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_KIT_DETAIL, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_KIT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_KIT_CREATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_KIT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_KIT_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_KIT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_KIT_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_ITEM_CREATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_ITEM_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_ITEM_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_CATEGORY_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MARKETING_CATEGORY_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_CATEGORY_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_CATEGORY_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_CATEGORY_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_TYPE_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MARKETING_TYPE_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_TYPE_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_TYPE_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_TYPE_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.PUBLISH_MARKETING_KIT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_KIT_PUBLISH, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_SHOP_CATEGORY_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_SHOP_CATEGORY_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_SHOP_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SHOP_CATEGORY_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_SHOP_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SHOP_CATEGORY_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_SHOP_CATEGORY]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_SHOP_CATEGORY_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_PRO_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MARKETING_PRO_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_PRO_DETAIL]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_DETAIL, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_PRO]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_CREATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_PRO]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_PRO]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.BACKGROUND_MARKETING_PRO]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_BACKGROUND, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_PRO_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_ITEM_CREATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_PRO_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_ITEM_UPDATE, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_PRO_ITEM]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_ITEM_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.PUBLISH_MARKETING_PRO]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_PUBLISH, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_MARKETING_PRO_TYPE_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_MARKETING_PRO_TYPE_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_MARKETING_PRO_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_TYPE_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_MARKETING_PRO_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_TYPE_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_MARKETING_PRO_TYPE]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_MARKETING_PRO_TYPE_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.GET_FONT_LIST]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_FONT_LIST, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.CREATE_FONT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_FONT_CREATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.UPDATE_FONT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_FONT_UPDATE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  },
  [types.DELETE_FONT]: (state, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_FONT_DELETE, payload)
        .then(response => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            state.commit(types.MUTATE_LOGOUT);
            reject(error.message);
          } else {
            reject(error.message);
          }
        });
    });
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
