<template>
  <button
    class="my-button"
    :class="{
      'my-button--outline': type === 'outline',
      'my-button--outline-blue': type === 'outline-blue',
      'my-button--orange': type === 'orange',
      'my-button--blue': type === 'blue',
      'my-button--sm': size === 'sm',
      'my-button--lg': size === 'lg',
      'my-button--flat': flat || flat === '',
      'my-button--narrow': narrow || narrow === '',
      'my-button--block': block || block === '',
      'my-button--rounded': rounded !== false
    }"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "normal"
    },
    flat: {
      default: false
    },
    narrow: {
      default: false
    },
    size: {
      type: String,
      default: "md" // md/sm/ls
    },
    disabled: {
      default: false
    },
    block: {
      default: false
    },
    rounded: {
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.my-button {
  height: 36px;
  border-radius: 5px;
  padding: 0 34px;
  border: none;
  color: #fff;
  font-weight: bold;
}

.my-button--sm {
  height: 30px;
  border-radius: 3px;
  font-size: 13px;
  padding: 0 13px;
}

.my-button--lg {
  height: 45px;
  font-size: 14px;
  font-weight: bold;
}

.my-button--flat {
  padding: 0 56px;
}

.my-button--narrow {
  padding: 0 14px;
}

.my-button--block {
  width: 100%;
}

.my-button--rounded {
  border-radius: 40px;
}

.my-button--outline {
  border: solid 1px #7a7a7a;
  background-color: #ffffff;
  color: #7a7a7a;
}

.my-button--outline:hover {
  background-color: #f8f0f0;
}

.my-button--outline-blue {
  border: solid 1px #3bbaed;
  background-color: #ffffff;
  color: #3bbaed;
}

.my-button--outline-blue:hover {
  background-color: #3bbaed;
  color: #fff;
}

.my-button--orange {
  border: solid 1px #f08045;
  background-color: #f08045;
  color: #fff;
}

.my-button--orange:hover {
  background-color: #e77538;
}

.my-button--blue {
  border: solid 1px #3bbaed;
  background-color: #3bbaed;
  color: #fff;
}

.my-button--blue:hover {
  background-color: #27b1e7;
}
</style>
