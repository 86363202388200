import * as types from "../types";
import axios from "axios";

const API_URL = types.API_BASE_URL + "auth/login";

const state = {
  isLoggedIn: false,
  user: {
    name: "",
    role: [],
    token: "",
    permissions: []
  },
  token: null
};

const getters = {
  [types.IS_LOGGED_IN]: state => {
    return state.isLoggedIn;
  },
  [types.USER]: state => {
    return state.user;
  },
  [types.TOKEN]: state => {
    return state.token;
  }
};

const mutations = {
  [types.MUTATE_LOGIN]: (state, payload) => {
    localStorage.token = payload.token;
    localStorage.user = JSON.stringify(payload.data);
    state.user = payload.data;
    state.token = payload.token;
    state.isLoggedIn = true;
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.token;
  },
  [types.MUTATE_LOGOUT]: state => {
    state.isLoggedIn = false;
    state.user = {
      name: "",
      role: [],
      token: "",
      permissions: []
    };
    state.token = null;
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = "";
  }
};

const actions = {
  [types.LOGIN]: (state, credentials) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL, credentials)
        .then(response => {
          if (response.data.success) {
            if(response.data.data.role.some(item => item.id === 1)) {
              state.commit(types.MUTATE_LOGIN, response.data);
              resolve(response.data);
            } else {
              reject("Not admin account");
            }
            
          } else {
            state.commit(types.MUTATE_LOGOUT);
            reject(response.data.message);
          }
        })
        .catch(error => {
          state.commit(types.MUTATE_LOGOUT);
          reject(error.message);
        });
    });
  },
  [types.LOGOUT]: ({ commit }, payload) => {
    commit(types.MUTATE_LOGOUT, payload);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
