import * as types from "./types";

export default {
  [types.MUTATE_SET_PAGE_TITLE]: (state, payload) => {
    state.pageTitle = payload;
  },
  [types.MUTATE_SIDEBAR_NAME]: (state, payload) => {
    state.sidebarName = payload;
  },
  [types.MUTATE_SET_SIDEBAR_COLLAPSED]: (state, payload) => {
    state.sidebarCollapsed = payload;
  }
};
