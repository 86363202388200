<template>
  <div
    id="app"
    :class="{
      'app--sidebar-collapsed': sidebarCollapsed,
      'app--sidebar-expanded': !sidebarCollapsed
    }"
  >
    <topbar @menubarClicked="collapsed = !collapsed" :collapsed="collapsed">
    </topbar>

    <div class="main">
      <sidebar :collapsed="collapsed" @onCollapsed="collapsed = true">
      </sidebar>

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      collapsed: false
    };
  },

  methods: {
    ...mapActions({
      setUserInfo: types.LOGIN
    })
  },

  computed: {
    ...mapGetters({
      isLoggedIn: types.IS_LOGGED_IN,
      sidebarCollapsed: types.SIDEBAR_COLLAPSED
    })
  },

  mounted() {
    if (window.innerWidth < 900) {
      this.collapsed = true;
    }

    // this.setUserInfo({
    //     username: 'user1',
    //     role: 'User'
    // });
  }
};
</script>

<style>
.sidebar--user {
  width: 273px;
}
.app--sidebar-collapsed .sidebar--user {
  margin-left: -273px;
}
</style>
