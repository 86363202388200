<template>
  <div
    class="sidebar"
    :class="{
      'sidebar--collapsed': collapsed,
      'sidebar--expanded': !collapsed
    }"
    ref="sidebar"
  >
    <template v-for="(item, i) in menuItems">
      <div
        :key="i"
        @mouseover="handleMouseHover($event, i)"
        @click="
          showingHover = false;
          handleLinkClick(i, item);
        "
        :class="{ 'sub-item': item.subMenus }"
      >
        <router-link
          class="sidebar__item"
          :class="{
            'sidebar__item--active':
              activeItemIndex === i || (hoverIndex === i && showingHover)
          }"
          :to="item.to"
          v-if="!item.subMenus"
        >
          <fa-icon :icon="item.icon" class="my-icon" />

          <div class="sidebar-item-text">
            {{ item.name }}
          </div>
        </router-link>

        <router-link
          class="sidebar__item"
          :class="{
            'sidebar__item--active':
              activeItemIndex === i || (hoverIndex === i && showingHover)
          }"
          v-else
          :to="item.to"
          v-on:click.native="toggleSubmenu(item, i)"
        >
          <fa-icon :icon="item.icon" class="my-icon" />

          <div class="sidebar-item-text space-between align-center">
            <span>
              {{ item.name }}
            </span>
            <div
              :class="{
                'arrow-down': !item.expanded,
                'arrow-up': item.expanded
              }"
            ></div>
          </div>
        </router-link>

        <template v-for="(itm, ii) in item.subMenus">
          <transition name="height" :key="ii">
            <router-link
              :key="ii"
              class="sidebar__item sidebar__item--sub"
              :to="itm.to"
              v-if="item.expanded && !collapsed"
              v-on:click.native="handleSubItemClick"
            >
              <fa-icon :icon="itm.icon" class="my-icon" />

              <div class="sidebar-item-text">
                {{ itm.name }}
              </div>
            </router-link>
          </transition>
        </template>
      </div>
    </template>

    <div
      class="test-hover"
      ref="testHover"
      @mouseleave="hideMenu"
      :style="{ visibility: showingHover ? 'visible' : 'hidden' }"
    >
      <div
        :key="i"
        v-for="(item, i) in menuItems"
        @click="showingHover = false"
      >
        <router-link
          class="sidebar__item"
          :class="{ 'sidebar__item--active': hoverIndex === i }"
          :to="item.to"
          v-if="hoverIndex === i"
        >
          <!-- <div class="sidebar-item-text--hover">
                            {{ item.name }}
                        </div> -->

          <div class="sidebar-item-text--hover space-between align-center">
            <span>
              {{ item.name }}
            </span>
            <div class="arrow-down" v-if="item.subMenus"></div>
          </div>
        </router-link>

        <template v-for="(itm, ii) in item.subMenus">
          <router-link
            :key="ii"
            class="sidebar__item sidebar__item--sub collapse-sub"
            :to="itm.to"
            v-if="hoverIndex === i"
            v-on:click.native="activeItemIndex = i"
          >
            <fa-icon :icon="itm.icon" class="my-icon" />

            <div class="sidebar-item-text--collapsed">
              {{ itm.name }}
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      menuItems: [
        { name: "Beranda", icon: "home", to: "/home" },
        { 
          name: "Toko", 
          icon: "cube", 
          to: "/shop/category",
          expanded: false,
          subMenus: [
            {
              name: "Category",
              icon: "plus-circle",
              to: "/shop/category"
            },
            {
              name: "List",
              icon: "plus-circle",
              to: "/shop/list"
            }
          ]
        },
        { name: "Pemesanan", icon: "shopping-cart", to: "/order/list" },
        { name: "User", icon: "user-friends", to: "/user/list" },
        { 
          name: "Marketing Kit", 
          icon: "list-ul", 
          to: "/marketing-kit/category",
          expanded: false,
          subMenus: [
            {
              name: "Category",
              icon: "plus-circle",
              to: "/marketing-kit/category"
            },
            {
              name: "Type",
              icon: "plus-circle",
              to: "/marketing-kit/type"
            },
            {
              name: "List",
              icon: "plus-circle",
              to: "/marketing-kit/list"
            }
          ]
        },
        { 
          name: "Marketing Kit Pro", 
          icon: "list-ul", 
          to: "/marketing-kit-pro/type",
          expanded: false,
          subMenus: [
            {
              name: "Type",
              icon: "plus-circle",
              to: "/marketing-kit-pro/type"
            },
            {
              name: "Font",
              icon: "plus-circle",
              to: "/marketing-kit-pro/font"
            },
            {
              name: "List",
              icon: "plus-circle",
              to: "/marketing-kit-pro/list"
            }
          ]
        }

      ],
      activeItemIndex: 0,
      hoverIndex: -1,
      showingHover: false
    };
  },

  methods: {
    ...mapActions({
      logout: types.LOGOUT
    }),
    handleLinkClick(i, item) {
      if (item.to === "/logout") {
        this.logout();
      }
      this.activeItemIndex = i;
      if (window.innerWidth < 900 && !item.subMenus) {
        this.$emit("onCollapsed");
      }
    },

    handleMouseHover(e, i) {
      this.hoverIndex = i;
      if (
        this.$refs.sidebar.clientWidth < 155 &&
        e.target.classList.contains("sidebar__item")
      ) {
        let topHeight = e.target.offsetTop + 77 - this.$refs.sidebar.scrollTop;
        // this.$refs.testHover.style.visibility = 'visible' ;
        this.$refs.testHover.style.top = topHeight + "px";
        this.showingHover = true;
      }
    },

    hideMenu() {
      // this.$refs.testHover.style.visibility = 'hidden';
      this.showingHover = false;
    },

    toggleSubmenu(item, i) {
      item.expanded = !item.expanded;

      // console.log(this.menuItems[i].expanded);
      // this.menuItems[i].expanded = true;
      // console.log(this.menuItems[i].expanded);

      this.$forceUpdate();
    },

    handleSubItemClick() {
      if (window.innerWidth < 900) {
        this.$emit("onCollapsed");
      }
    }
  },

  props: {
    collapsed: {
      default: false
    }
  }
};
</script>
