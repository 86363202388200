import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import MyComponents from "./components";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.css";
import "./assets/css/utility.css";

// Install BootstrapVue
Vue.use(ElementUI, {locale});
Vue.use(BootstrapVue);
Vue.use(MyComponents);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
