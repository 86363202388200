/*=============== Base URL ============*/
export const API_BASE_URL = "https://api.bislin.com/";

/*================ Getters ============*/
// General / Shared
export const PAGE_TITLE = "pageTitle";
export const SIDEBAR_NAME = "sidebarName";
export const SIDEBAR_COLLAPSED = "sidebarCollapsed";

// auth module
export const IS_LOGGED_IN = "isLoggedIn";
export const USER = "user";
export const TOKEN = "token";

/*============= Mutations ===============*/
// General / Shared
export const MUTATE_SET_PAGE_TITLE = "mutateSetPageTitle";
export const MUTATE_SIDEBAR_NAME = "mutateSidebarName";
export const MUTATE_SET_SIDEBAR_COLLAPSED = "mutateSidebarCollapsed";

// auth module
export const MUTATE_LOGIN = "mutateLogin";
export const MUTATE_LOGOUT = "mutateLogout";

/*=============== Actions =================*/
// General / Shared
export const SET_PAGE_TITLE = "setPageTitle";
export const SET_SIDEBAR_NAME = "setSidebarName";
export const SET_SIDEBAR_COLLAPSED = "setSidebarCollapsed";

// auth module
export const LOGIN = "login";
export const LOGOUT = "logout";

// shop
export const GET_SHOP_LIST = "GET_SHOP_LIST";
export const VERIFY_SHOP = "VERIFY_SHOP";
export const CREATE_SHOP = "CREATE_SHOP";

export const GET_SHOP_CATEGORY_LIST = "GET_SHOP_CATEGORY_LIST";
export const CREATE_SHOP_CATEGORY = "CREATE_SHOP_CATEGORY";
export const UPDATE_SHOP_CATEGORY = "UPDATE_SHOP_CATEGORY";
export const DELETE_SHOP_CATEGORY = "DELETE_SHOP_CATEGORY";

// role
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

// user
export const GET_USER_LIST = "GET_USER_LIST";
export const VERIFY_USER = "VERIFY_USER";

// order
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";

//marketing kit
export const GET_MARKETING_KIT_LIST = "GET_MARKETING_KIT_LIST";
export const GET_MARKETING_KIT_DETAIL = "GET_MARKETING_KIT_DETAIL";
export const CREATE_MARKETING_KIT = "CREATE_MARKETING_KIT";
export const UPDATE_MARKETING_KIT = "UPDATE_MARKETING_KIT";
export const DELETE_MARKETING_KIT = "DELETE_MARKETING_KIT";
export const CREATE_MARKETING_ITEM = "CREATE_MARKETING_ITEM";
export const UPDATE_MARKETING_ITEM = "UPDATE_MARKETING_ITEM";
export const DELETE_MARKETING_ITEM = "DELETE_MARKETING_ITEM";
export const GET_MARKETING_CATEGORY_LIST = "GET_MARKETING_CATEGORY_LIST";
export const CREATE_MARKETING_CATEGORY = "CREATE_MARKETING_CATEGORY";
export const UPDATE_MARKETING_CATEGORY = "UPDATE_MARKETING_CATEGORY";
export const DELETE_MARKETING_CATEGORY = "DELETE_MARKETING_CATEGORY";
export const GET_MARKETING_TYPE_LIST = "GET_MARKETING_TYPE_LIST";
export const CREATE_MARKETING_TYPE = "CREATE_MARKETING_TYPE";
export const UPDATE_MARKETING_TYPE = "UPDATE_MARKETING_TYPE";
export const DELETE_MARKETING_TYPE = "DELETE_MARKETING_TYPE";
export const PUBLISH_MARKETING_KIT = "PUBLISH_MARKETING_KIT";

//marketing kit
export const GET_MARKETING_PRO_LIST = "GET_MARKETING_PRO_LIST";
export const GET_MARKETING_PRO_DETAIL = "GET_MARKETING_PRO_DETAIL";
export const CREATE_MARKETING_PRO = "CREATE_MARKETING_PRO";
export const UPDATE_MARKETING_PRO = "UPDATE_MARKETING_PRO";
export const DELETE_MARKETING_PRO = "DELETE_MARKETING_PRO";
export const BACKGROUND_MARKETING_PRO = "BACKGROUND_MARKETING_PRO";
export const CREATE_MARKETING_PRO_ITEM = "CREATE_MARKETING_PRO_ITEM";
export const UPDATE_MARKETING_PRO_ITEM = "UPDATE_MARKETING_PRO_ITEM";
export const DELETE_MARKETING_PRO_ITEM = "DELETE_MARKETING_PRO_ITEM";
export const PUBLISH_MARKETING_PRO = "PUBLISH_MARKETING_PRO";
export const GET_MARKETING_PRO_TYPE_LIST = "GET_MARKETING_PRO_TYPE_LIST";
export const CREATE_MARKETING_PRO_TYPE = "CREATE_MARKETING_PRO_TYPE";
export const UPDATE_MARKETING_PRO_TYPE = "UPDATE_MARKETING_PRO_TYPE";
export const DELETE_MARKETING_PRO_TYPE = "DELETE_MARKETING_PRO_TYPE";

//font
export const GET_FONT_LIST = "GET_FONT_LIST";
export const CREATE_FONT = "CREATE_FONT";
export const UPDATE_FONT = "UPDATE_FONT";
export const DELETE_FONT = "DELETE_FONT";