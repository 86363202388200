<template>
  <div class="topbar">
    <div
      class="topbar__left hide-mobile"
      :class="{ 'topbar__left--collapsed': collapsed }"
    >
      <img src="/img/logo.svg" alt="Logo" v-if="!collapsed" />
      <img src="/img/logogram.svg" alt="Logo" v-else />
    </div>

    <div class="topbar__right">
      <div class="topbar__right-left">
        <img
          src="/img/icons/menu.svg"
          alt="Menu"
          class="menu-icon"
          @click="handleMenubarClicked"
        />

        <!-- <img src="/img/icons/menu.svg"
            alt="Menu"
            class="menu-icon"
            v-else
          > -->
      </div>

      <div class="topbar__right-right">
        <div
          class="topbar-menu"
          @click="dropdownOpen = !dropdownOpen"
          tabindex="-1"
          @blur="handleBlur"
        >
          <div class="align-center p-relative dropdown">
            Go to Web App
            <div class="arrow-down ml-2"></div>
            <div class="dropdown-app" v-if="dropdownOpen">
              <div class="dropdown-menu-item">
                <img
                  src="/img/icons/video_inactive.svg"
                  alt=""
                  class="sidebar-item-icon"
                />
                <span>Video Bimbel</span>
              </div>

              <div class="dropdown-menu-item">
                <img
                  src="/img/icons/download_inactive.svg"
                  alt=""
                  class="sidebar-item-icon"
                />
                <span>Download Soal</span>
              </div>

              <div class="dropdown-menu-item">
                <img
                  src="/img/icons/tryoutonline_inactive.svg"
                  alt=""
                  class="sidebar-item-icon"
                />
                <span>Try Out Online</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="topbar-menu"
          @click="notificationOpen = !notificationOpen"
          tabindex="-1"
          @blur="handleNotificationBlur"
        >
          <div class="align-center p-relative dropdown">
            <div class="noti-count">
              2
            </div>
            <img src="/img/icons/notif.svg" alt="" />
            <div class="dropdown-notification" v-if="notificationOpen">
              <div class="notification-title space-between align-center">
                <strong class="c-pointer">
                  Notification
                </strong>

                <span class="c-pointer h-underlined" @click="preventHide">
                  Clear All
                </span>
              </div>
              <div class="notifications">
                <div class="notification-item notification-item--unseen">
                  <img
                    src="/img/icons/profilesample.svg"
                    alt=""
                    class="sidebar-item-icon"
                  />
                  <div>
                    <strong>
                      Lorem Ipsum Dolor
                    </strong>
                    <br />
                    <span class="lite-p">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </span>
                  </div>
                </div>
                <div class="notification-item">
                  <img
                    src="/img/icons/icon_notif_sample.svg"
                    alt=""
                    class="sidebar-item-icon"
                  />
                  <div>
                    <strong>
                      Lorem Ipsum Dolor
                    </strong>
                    <br />
                    <span class="lite-p">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    </span>
                  </div>
                </div>
              </div>
              <div class="view-all">
                View All
              </div>
            </div>
          </div>
        </div>
        <div class="topbar-menu hide-mobile">
          <div class="align-center" @click="logoutNow">
            <img
              src="/img/icons/signout_inactive.svg"
              alt="Signout"
              class="mr-2"
            />
            <span>
              &nbsp; Sign Out
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";

export default {
  data() {
    return {
      dropdownOpen: false,
      notificationOpen: false,
      selectedOption: "Video Bimbel"
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: types.IS_LOGGED_IN,
      sidebarCollapsed: types.SIDEBAR_COLLAPSED
    })
  },

  methods: {
    ...mapActions({
      logout: types.LOGOUT,
      setSidebarCollapsed: types.SET_SIDEBAR_COLLAPSED
    }),
    logoutNow() {
      this.logout();
      this.$router.push("/login");
    },
    handleBlur() {
      this.dropdownOpen = false;
    },
    handleNotificationBlur() {
      this.notificationOpen = false;
    },
    preventHide(e) {
      e.stopPropagation();
    },

    handleMenubarClicked() {
      this.$emit("menubarClicked");
      this.setSidebarCollapsed(!this.sidebarCollapsed);
    }
  },

  watch: {
    selectedOption: function(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal === "Video Bimbel") {
        this.$router.push("/user/video-bimbel/fisika");
      } else if (newVal === "Download Soal") {
        this.$router.push("/user/download-soal/tkpa");
      } else if (newVal === "Try Out Online") {
        this.$router.push("/user/try-out-online");
      } else if (newVal === "User Dashboard") {
        this.$router.push("/user/user-dashboard-account");
      }
    }
  },

  props: {
    collapsed: {
      default: false
    }
  }
};
</script>

<style>
.noti-count {
  position: absolute;
  z-index: 999;
  top: 23px;
  right: -4px;
  width: 12px;
  height: 12px;
  background-color: #ec407a;
  border-radius: 12px;
  font-size: 7px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
</style>
