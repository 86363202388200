import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes:  [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        layout: "default-layout"
      }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
      meta: {
        layout: "default-layout"
      }
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("../views/shop/Shop.vue"),
      children: [
        {
          path: "category",
          name: "shop-category",
          component: () =>
            import("../views/shop/ShopCategory.vue")
        },
        {
          path: "list",
          name: "shop-list",
          component: () =>
            import("../views/shop/ShopList.vue")
        },
        {
          path: "add",
          name: "shop-add",
          component: () =>
            import("../views/shop/ShopAdd.vue")
        }
      ]
    },
    {
      path: "/user",
      name: "user",
      component: () => import("../views/user/User.vue"),
      children: [
        {
          path: "list",
          name: "user-list",
          component: () =>
            import("../views/user/UserList.vue")
        }
      ]
    },
    {
      path: "/order",
      name: "order",
      component: () => import("../views/order/Order.vue"),
      children: [
        {
          path: "list",
          name: "user-list",
          component: () =>
            import("../views/order/OrderList.vue")
        }
      ]
    },
    {
      path: "/marketing-kit",
      name: "marketing-kit",
      component: () => import("../views/marketing-kit/MarketingKit.vue"),
      children: [
        {
          path: "list",
          name: "marketing-kit-list",
          component: () =>
            import("../views/marketing-kit/MarketingKitList.vue")
        },
        {
          path: "add",
          name: "marketing-kit-add",
          component: () =>
            import("../views/marketing-kit/MarketingKitAdd.vue")
        },
        {
          path: "edit/:id",
          name: "marketing-kit-edit",
          component: () =>
            import("../views/marketing-kit/MarketingKitEdit.vue")
        },
        {
          path: "category",
          name: "marketing-kit-category",
          component: () =>
            import("../views/marketing-kit/MarketingKitCategory.vue")
        },
        {
          path: "type",
          name: "marketing-kit-type",
          component: () =>
            import("../views/marketing-kit/MarketingKitType.vue")
        }
      ]
    },
    {
      path: "/marketing-kit-pro",
      name: "marketing-kit-pro",
      component: () => import("../views/marketing-kit-pro/MarketingKitPro.vue"),
      children: [
        {
          path: "list",
          name: "marketing-kit-pro-list",
          component: () =>
            import("../views/marketing-kit-pro/MarketingKitProList.vue")
        },
        {
          path: "add",
          name: "marketing-kit-pro-add",
          component: () =>
            import("../views/marketing-kit-pro/MarketingKitProAdd.vue")
        },
        {
          path: "edit/:id",
          name: "marketing-kit-pro-edit",
          component: () =>
            import("../views/marketing-kit-pro/MarketingKitProEdit.vue")
        },
        {
          path: "type",
          name: "marketing-kit-pro-type",
          component: () =>
            import("../views/marketing-kit-pro/MarketingKitProType.vue")
        },
        {
          path: "font",
          name: "marketing-kit-pro-font",
          component: () =>
            import("../views/marketing-kit-pro/MarketingKitProFont.vue")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path == "/" || to.path == "/login") {
    if (localStorage.user && localStorage.token) {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (localStorage.user && localStorage.token) {
      next();
    } else {
      next("/login");
    }
  }
});


export default router;
