import * as types from "./types";

export default {
  [types.PAGE_TITLE]: state => {
    return state.pageTitle;
  },

  [types.SIDEBAR_NAME]: state => {
    return state.sidebarName;
  },

  [types.SIDEBAR_COLLAPSED]: state => {
    return state.sidebarCollapsed;
  }
};
