import * as types from "./types";

export default {
  [types.SET_PAGE_TITLE]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_PAGE_TITLE, payload);
  },

  [types.SET_SIDEBAR_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_SIDEBAR_NAME, payload);
  },

  [types.SET_SIDEBAR_COLLAPSED]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_SIDEBAR_COLLAPSED, payload);
  }
};
